import * as PopoverPrimitives from "@radix-ui/react-popover";
import React, { forwardRef, useState } from "react";
import { FaPopperOverlay } from "../../FaPopperOverlay/FaPopperOverlay";

interface IFaPopoverRootProps extends PopoverPrimitives.PopoverProps {
    onOverlayClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    /** Giving this a value overwrites the automatic overlay controls. */
    controlOverlayVisible?: boolean;
}

export const FaPopoverRoot = forwardRef<HTMLDivElement, IFaPopoverRootProps>((props, forwardedRef) => {
    const { onOverlayClick, onOpenChange, controlOverlayVisible: overlayVisible, open: controlledOpen, ...rootProps } = props;
    const [open, setOpen] = useState(false);

    return (
        <PopoverPrimitives.Root
            {...rootProps}
            open={controlledOpen ?? open}
            onOpenChange={value => {
                setOpen(value);
                onOpenChange?.(value);
            }}>
            <FaPopperOverlay ref={forwardedRef} open={overlayVisible ?? controlledOpen ?? open} onClick={onOverlayClick} />
            {props.children}
        </PopoverPrimitives.Root>
    );
});
