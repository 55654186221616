import { forwardRef } from "react";
import * as PopoverPrimitives from "@radix-ui/react-popover";
import "./faPopoverContent.scss";
import clsx from "clsx";

interface IFaPopoverContentProps extends PopoverPrimitives.PopoverContentProps { }

export const FaPopoverContent = forwardRef<HTMLDivElement, IFaPopoverContentProps>((props, forwardedRef) => {
    const { children, sideOffset, collisionPadding, className, ...contentProps } = props;

    return (
        <PopoverPrimitives.Portal>
            <PopoverPrimitives.Content
                ref={forwardedRef}
                className={clsx("fa-popover-content", className)}
                sideOffset={sideOffset ?? 8}
                collisionPadding={collisionPadding ?? 16}
                {...contentProps}>
                {children}
            </PopoverPrimitives.Content>
        </PopoverPrimitives.Portal>
    );
});
