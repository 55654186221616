import * as PopoverPrimitives from "@radix-ui/react-popover";
import clsx from "clsx";

interface IFaPopoverTriggerProps extends Omit<PopoverPrimitives.PopoverTriggerProps, "asChild"> {
    /**
     * Decides, if the trigger should take over it's child or render as a separate <button> element.
     * If true the child element gets taken.
     * @default true
     */
    asChild?: boolean
}

export function FaPopoverTrigger(props: IFaPopoverTriggerProps) {
    const { children, className, asChild = true, ...triggerProps } = props;
    return (
        <PopoverPrimitives.Trigger className={clsx("fa-popover-trigger", className)} {...triggerProps} asChild={asChild}>
            {children}
        </PopoverPrimitives.Trigger>
    );
}
